import {
  SEARCH_BROKER_FAILED,
  SEARCH_BROKER_RESET,
  SEARCH_BROKER_START,
  SEARCH_BROKER_SUCCESS,
} from 'client/dhv-form/api/client/actions/broker.action'
import { dhvFormStateDefault } from 'dhv-common'

export function brokerSearching(
  state: boolean = dhvFormStateDefault.completeStep.brokerSearching || false,
  action: any
): boolean {
  if (action.type === SEARCH_BROKER_START) {
    return true
  } else if (action.type === SEARCH_BROKER_FAILED) {
    return false
  } else if (action.type === SEARCH_BROKER_SUCCESS) {
    return false
  } else if (action.type === SEARCH_BROKER_RESET) {
    return false
  } else {
    return state
  }
}
