import {
  Broker,
  SEARCH_BROKER_RESET,
  SEARCH_BROKER_START,
  SEARCH_BROKER_SUCCESS,
} from 'client/dhv-form/api/client/actions/broker.action'
import { dhvFormStateDefault } from 'dhv-common'

export function brokerResult(state: Broker[] = dhvFormStateDefault.completeStep.brokerResult || [], action: any): Broker[] {
  if (action.type === SEARCH_BROKER_START) {
    return []
  } else if (action.type === SEARCH_BROKER_SUCCESS) {
    return action.payload ? action.payload : []
  } else if (action.type === SEARCH_BROKER_RESET) {
    return []
  } else {
    return state
  }
}
