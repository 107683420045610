import { Broker } from 'client/dhv-form/api/client/actions/broker.action'
import React from 'react'

interface Props {
  broker: Broker
  selected: boolean
  selectBroker: (broker: Broker) => void
  removeBroker: () => void
}

export class Card extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { broker, selectBroker, selected } = this.props
    return (
      <div className="card">
        <div className="header">{broker.name}</div>
        <div>{broker.street}</div>
        <div>
          {broker.postalCode} {broker.city}
        </div>
        <br></br>
        <div>
          <span className="icon icon--phone_24_solid" />
          {broker.phone}
        </div>
        <div>
          <span className="icon icon--mail-close_24_solid" />
          {broker.email}
        </div>

        <button
          className="btn btn--secondary btn-final"
          style={{ width: '100%' }}
          onClick={selected ? () => this.props.removeBroker() : () => this.props.selectBroker(broker)}
          type="button"
        >
          <span className="icon i-cta" />
          {selected ? 'Entfernen' : 'Wählen'}
        </button>
      </div>
    )
  }
}
