import { Broker, SEARCH_BROKER_RESET, SEARCH_BROKER_START } from 'client/dhv-form/api/client/actions/broker.action'
import { dhvFormStateDefault } from 'dhv-common'

export function broker(state: Broker | null = dhvFormStateDefault.completeStep.broker || null, action: any): Broker | null {
  if (action.type === SEARCH_BROKER_START) {
    return null
  } else if (action.type === SEARCH_BROKER_RESET) {
    return action.broker
  } else {
    return state
  }
}
