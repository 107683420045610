import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'
import { brokerId } from './brokerId.reducer'
import { brokerEmail } from './brokerEmail.reducer'
import { disableBrokerFields } from './disableBrokerFields.reducer'
import { brokerSearch } from './brokerSearch.reducer'
import { brokerSearching } from './brokerSearching.reducer'
import { brokerError } from './brokerError.reducer'
import { brokerResult } from './brokerResult.reducer'
import { broker } from './broker.reducer'

export const completeStepReducer = combineForms(
  {
    acceptedContact: false,
    acceptedPhone: false,
    acceptedEmail: false,
    acceptedMobile: false,
    acceptedSms: false,
    brokerSearch: brokerSearch,
    brokerSearching: brokerSearching,
    brokerError: brokerError,
    brokerResult: brokerResult,
    broker: broker,
    brokerId: brokerId,
    brokerEmail: brokerEmail,
    brokerEmail2: dhvFormStateDefault.completeStep.brokerEmail2,
    disableBrokerFields: disableBrokerFields,
    milesAndMore: dhvFormStateDefault.completeStep.milesAndMore,
  },
  'dhvForm.completeStep'
)
