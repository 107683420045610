import axios, { AxiosError, AxiosResponse } from 'axios'

export const SEARCH_BROKER_SUCCESS = 'SEARCH_BROKER_SUCCESS'
export const SEARCH_BROKER_FAILED = 'SEARCH_BROKER_FAILED'
export const SEARCH_BROKER_START = 'SEARCH_BROKER_START'
export const SEARCH_BROKER_RESET = 'SEARCH_BROKER_RESET'

export const startSearchBroker = async (searchTerm: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: SEARCH_BROKER_START,
    })
    try {
      const broker = await getBrokerRequest2(searchTerm)
      if (!broker) {
        return dispatch({
          type: SEARCH_BROKER_FAILED,
          payload: broker,
        })
      }
      return dispatch({
        type: SEARCH_BROKER_SUCCESS,
        payload: broker,
      })
    } catch (error) {
      console.error('Error in requestSearchBroker:', error)
      return dispatch({
        type: SEARCH_BROKER_FAILED,
      })
    }
  }
}
export const startSearchBrokerFetch = async (searchTerm: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: SEARCH_BROKER_START,
    })
    try {
      const broker = await getBrokerRequestFetch(searchTerm)
      if (!broker) {
        return dispatch({
          type: SEARCH_BROKER_FAILED,
          payload: broker,
        })
      }
      return dispatch({
        type: SEARCH_BROKER_SUCCESS,
        payload: broker,
      })
    } catch (error) {
      console.error('Error in requestSearchBroker:', error)
      return dispatch({
        type: SEARCH_BROKER_FAILED,
      })
    }
  }
}

export const selectBroker = async (broker: Broker) => {
  return async (dispatch: any) => {
    return dispatch({
      type: SEARCH_BROKER_RESET,
      payload: '',
      broker: broker,
      brokerSearch: '',
      brokerId: broker.id,
      brokerEmail: broker.email,
    })
  }
}

export const removeBroker = async (broker: Broker) => {
  return async (dispatch: any) => {
    return dispatch({
      type: SEARCH_BROKER_RESET,
      payload: '',
      broker: null,
      brokerSearch: '',
      brokerId: '',
    })
  }
}

export const resetSearchBroker = async (searchTerm: string) => {
  return async (dispatch: any) => {
    return dispatch({
      type: SEARCH_BROKER_RESET,
    })
  }
}

export interface Broker {
  id: string
  name: string
  street: string
  postalCode: string
  city: string
  phone: string
  email: string
}

/* if (
      this.agenturDaten?.AgenturBild &&
      !this.agenturDaten?.AgenturBild?.UrlEinzelBild &&
      !this.agenturDaten?.AgenturBild?.UrlGruppenBild
    ) {
      return this.assetsBaseUrl + '/assets/images/Teaser_Agent_Box_Fallback.png'
    }
    if (this.agenturDaten.AgenturBild) {
      if (this.agenturDaten.AgenturBild.UrlGruppenBild) {
        return this.agenturDaten.AgenturBild.UrlGruppenBild
      }
      if (this.agenturDaten.AgenturBild.UrlEinzelBild) {
        return this.agenturDaten.AgenturBild.UrlEinzelBild
      }
    }
    return null */

function mapBrokerValues(values: {
  OnlineDataID: string
  Name: string
  Location: {
    Street: string
    PostalCode: string
    City: string
    Phone: string
  }
  Email: string
  /* AgenturBild: {
    UrlEinzelBild: string
    UrlGruppenBild: string
  } */
}): Broker {
  return {
    id: values.OnlineDataID,
    name: values.Name,
    street: values.Location.Street,
    postalCode: values.Location.PostalCode,
    city: values.Location.City,
    phone: values.Location.Phone,
    email: values.Email,
  }
}

export const getBrokerRequest2 = async (searchTerm: string): Promise<Broker[] | null> => {
  axios.defaults.withCredentials = true
  return axios
    .get(`https://api-de-uat-de.zurich.de/qa/sales/zc/publicaccess/zurichde/FindAgenturen?searchTerm=${searchTerm}`, {
      timeout: 3000,
      withCredentials: true,
      headers: {
        KeyId: '9296c6b4-3e8a-44e4-90d9-27eb7eac5a40',
      },
    })
    .then(function (response: AxiosResponse) {
      if (response && response.data && response.status === 200) {
        let broker = response.data
        /* broker.push({
          Name: 'Seuffert GmbH',
          IndividualWebsite: false,
          IsVisible: true,
          CompanyID: 21,
          DataID: 6713340,
          OnlineDataID: 216071655,
          ID: '{813106F4-531A-4F72-9FE6-0C19B83C0918}',
          Distance: 0,
          Email: 'seuffert.sennfeld@zuerich.de',
          Facebook: 'https://www.facebook.com/seuffertgmbh',
          FacebookTarget: '',
          Firstname: 'Regionaldirektion',
          Instagram: 'https://www.instagram.com/seuffertgmbh/',
          InstagramTarget: '',
          Lastname: 'Seuffert GmbH',
          LegalForm: null,
          LicenseManagingDirectory: '',
          LicenseParagraphNumber: '§ 34d Absatz 1 GewO',
          ManagingDirector: '',
          Mobile: '0171 4752318 (H. Demel), 0171 7508423 (K. Geyer)',
          MarkMobileNumberAsWhatsApp: false,
          Owner: '',
          Picture:
            '/-/media/project/zwp/germany/agents/s/seuffert/seuffert_brokerbild_1200x600_2023_08.jpg?rev=1718f01bbcec4997aae98a06fde1878a\u0026hash=78DFA5DA1B8094A1EF96D4BD6E047E00',
          Bio: null,
          RegisterCourts: null,
          Representant: '',
          SearchKeywords: 'SEUFFERTGMBH;SEUFFERT, demel, geyer',
          Twitter: '',
          TwitterTarget: '',
          LinkedIn: 'https://www.linkedin.com/company/seuffertgmbh/',
          LinkedInTarget: '',
          Type: 'Regionaldirektion',
          VanityUrl: 'seuffert',
          VATNumber: '',
          WelcomeText:
            'Herzlich Willkommen! Die Seuffert GmbH ist als zuständige Filialdirektion Ihr kompetenter Ansprechpartner im Großraum Ochsenfurt/Weikersheim/Kitzingen/Neustadt a. d. Aisch. Wir freuen uns darauf, Sie individuell zu beraten und zu betreuen und stehen auch persönlich jederzeit zu Ihrer Verfügung. Für die Qualität unserer Angebote steht die Zurich Gruppe Deutschland.',
          Youtube: '',
          YoutubeTarget: '',
          Title: 'Herr',
          SelectedAgencyName: 'Seuffert GmbH in Ochsenfurt, Schweinfurt und Sennfeld',
          MoreAboutAgentText: null,
          Xing: '',
          XingTarget: '',
          Location: {
            Id: '',
            City: 'Sennfeld',
            Country: '',
            Fax: ' ',
            Latitude: '50.0396826',
            Longitude: '10.2569369',
            OpeningHours: '\n\nMontag- Freitag 9:00 bis 13:00 Uhr\u003cbr /\u003e\n\nund nach Vereinbarung',
            Parking: '',
            Phone: '09721 776046 (H. Demel),  09721 68114 (K. Geyer)',
            PostalCode: '97526',
            PublicTransportation: '',
            Street: 'Dachsgrube 17',
          },
          AgentUrl: '/de-de/vor-ort/seuffert',
          HideInAgencySearch: false,
          Bookings: 'https://outlook.office365.com/owa/calendar/SeuffertGmbH@zurichinsurance.onmicrosoft.com/bookings/',
          SpecialProducts: [],
          ChannelId: '',
          WidgetId: '',
          TsId: '',
        })
        broker.push({
          Name: 'Seuffert GmbH',
          IndividualWebsite: false,
          IsVisible: true,
          CompanyID: 21,
          DataID: 6713340,
          OnlineDataID: 216071655,
          ID: '{813106F4-531A-4F72-9FE6-0C19B83C0918}',
          Distance: 0,
          Email: 'seuffert.sennfeld@zuerich.de',
          Facebook: 'https://www.facebook.com/seuffertgmbh',
          FacebookTarget: '',
          Firstname: 'Regionaldirektion',
          Instagram: 'https://www.instagram.com/seuffertgmbh/',
          InstagramTarget: '',
          Lastname: 'Seuffert GmbH',
          LegalForm: null,
          LicenseManagingDirectory: '',
          LicenseParagraphNumber: '§ 34d Absatz 1 GewO',
          ManagingDirector: '',
          Mobile: '0171 4752318 (H. Demel), 0171 7508423 (K. Geyer)',
          MarkMobileNumberAsWhatsApp: false,
          Owner: '',
          Picture:
            '/-/media/project/zwp/germany/agents/s/seuffert/seuffert_brokerbild_1200x600_2023_08.jpg?rev=1718f01bbcec4997aae98a06fde1878a\u0026hash=78DFA5DA1B8094A1EF96D4BD6E047E00',
          Bio: null,
          RegisterCourts: null,
          Representant: '',
          SearchKeywords: 'SEUFFERTGMBH;SEUFFERT, demel, geyer',
          Twitter: '',
          TwitterTarget: '',
          LinkedIn: 'https://www.linkedin.com/company/seuffertgmbh/',
          LinkedInTarget: '',
          Type: 'Regionaldirektion',
          VanityUrl: 'seuffert',
          VATNumber: '',
          WelcomeText:
            'Herzlich Willkommen! Die Seuffert GmbH ist als zuständige Filialdirektion Ihr kompetenter Ansprechpartner im Großraum Ochsenfurt/Weikersheim/Kitzingen/Neustadt a. d. Aisch. Wir freuen uns darauf, Sie individuell zu beraten und zu betreuen und stehen auch persönlich jederzeit zu Ihrer Verfügung. Für die Qualität unserer Angebote steht die Zurich Gruppe Deutschland.',
          Youtube: '',
          YoutubeTarget: '',
          Title: 'Herr',
          SelectedAgencyName: 'Seuffert GmbH in Ochsenfurt, Schweinfurt und Sennfeld',
          MoreAboutAgentText: null,
          Xing: '',
          XingTarget: '',
          Location: {
            Id: '',
            City: 'Sennfeld',
            Country: '',
            Fax: ' ',
            Latitude: '50.0396826',
            Longitude: '10.2569369',
            OpeningHours: '\n\nMontag- Freitag 9:00 bis 13:00 Uhr\u003cbr /\u003e\n\nund nach Vereinbarung',
            Parking: '',
            Phone: '09721 776046 (H. Demel),  09721 68114 (K. Geyer)',
            PostalCode: '97526',
            PublicTransportation: '',
            Street: 'Dachsgrube 17',
          },
          AgentUrl: '/de-de/vor-ort/seuffert',
          HideInAgencySearch: false,
          Bookings: 'https://outlook.office365.com/owa/calendar/SeuffertGmbH@zurichinsurance.onmicrosoft.com/bookings/',
          SpecialProducts: [],
          ChannelId: '',
          WidgetId: '',
          TsId: '',
        }) */
        broker = broker.map((broker: any) => mapBrokerValues(broker))
        return broker
      }
    })
    .catch(function (_error: AxiosError) {
      // handle error
      console.error('ZCockpit Broker API failed')
      return null
    })
}

export const getBrokerRequestFetch = async (searchTerm: string): Promise<Broker[] | null> => {
  const url = `https://api-de-uat-de.zurich.de/qa/sales/zc/publicaccess/zurichde/FindAgenturen?searchTerm=${searchTerm}`
  const headers = {
    KeyId: '9296c6b4-3e8a-44e4-90d9-27eb7eac5a40',
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include', // equivalent to axios' withCredentials
      headers: headers,
    })

    if (response.ok) {
      const data = await response.json()
      let broker = data
      broker = broker.map((broker: any) => mapBrokerValues(broker))
      return broker
    } else {
      console.error('ZCockpit Broker API failed with status', response.status)
      return null
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.error('ZCockpit Broker API request timed out')
    } else {
      console.error('ZCockpit Broker API failed', error)
    }
    return null
  }
}
