// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.complete-form .btn-final,
.complete-form .btn-prev {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 555px) {
  .complete-form .cta.cta-2 .icon,
  .cta.cta-3 .icon {
    font-size: 3.4rem;
  }
}

@media (max-width: 575px) {
  .complete-form .row {
    margin-bottom: 18px;
  }

  .complete-form .summary label {
    margin-bottom: 0;
    font-weight: bold;
  }

  .complete-form .form-control-static {
    padding-top: 7px;
  }

  .complete-form h4 {
    font-size: 1.7rem;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .complete-form h3 {
    font-size: 2rem;
  }
  .complete-form .cta.cta-2 .icon,
  .cta.cta-3 .icon {
    width: 45px;
  }
  .complete-form .cta.cta-3 {
    font-size: 1.7rem;
  }
}

.card-container {
  display: flex;
  gap: 25px;

  .card {
      flex: 1;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      min-width: 300px !important;

      .icon {
        margin-right: 10px;
      }

      .header {
        font-size: 18px;
        font-family: ZurichSans-SemiBold, Arial, sans-serif;
      }

      .card div {
        font-size: 16px;
      }
    }
}



`, "",{"version":3,"sources":["webpack://./src/client/dhv-form-complete/ui/client/complete-step.css"],"names":[],"mappings":"AAAA;;EAEE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE;;IAEE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;EACA;;IAEE,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,aAAa;EACb,SAAS;;EAET;MACI,OAAO;MACP,yBAAyB;MACzB,mBAAmB;MACnB,wCAAwC;MACxC,aAAa;MACb,2BAA2B;;MAE3B;QACE,kBAAkB;MACpB;;MAEA;QACE,eAAe;QACf,mDAAmD;MACrD;;MAEA;QACE,eAAe;MACjB;IACF;AACJ","sourcesContent":[".complete-form .btn-final,\n.complete-form .btn-prev {\n  margin-left: 0;\n  margin-right: 0;\n}\n\n@media (max-width: 555px) {\n  .complete-form .cta.cta-2 .icon,\n  .cta.cta-3 .icon {\n    font-size: 3.4rem;\n  }\n}\n\n@media (max-width: 575px) {\n  .complete-form .row {\n    margin-bottom: 18px;\n  }\n\n  .complete-form .summary label {\n    margin-bottom: 0;\n    font-weight: bold;\n  }\n\n  .complete-form .form-control-static {\n    padding-top: 7px;\n  }\n\n  .complete-form h4 {\n    font-size: 1.7rem;\n    margin-top: 15px;\n    margin-bottom: 15px;\n  }\n\n  .complete-form h3 {\n    font-size: 2rem;\n  }\n  .complete-form .cta.cta-2 .icon,\n  .cta.cta-3 .icon {\n    width: 45px;\n  }\n  .complete-form .cta.cta-3 {\n    font-size: 1.7rem;\n  }\n}\n\n.card-container {\n  display: flex;\n  gap: 25px;\n\n  .card {\n      flex: 1;\n      background-color: #ffffff;\n      border-radius: 10px;\n      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n      padding: 20px;\n      min-width: 300px !important;\n\n      .icon {\n        margin-right: 10px;\n      }\n\n      .header {\n        font-size: 18px;\n        font-family: ZurichSans-SemiBold, Arial, sans-serif;\n      }\n\n      .card div {\n        font-size: 16px;\n      }\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
