import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router'
import { Form, Control } from 'react-redux-form'
import { FormGroup, Row, Col, ControlLabel, HelpBlock, FormControl } from 'react-bootstrap'
import Spinner from 'react-spinner'

import { Person, getFullName } from 'dhv-person'
import { FieldSet, FieldSetContent, Checkbox, FormGroup as ZFormGroup, Text } from 'zur-ui-forms'
import { currencyFormatter } from 'common/dhv-common'
import { resetStorage } from 'client/dhv-common'
import { DhvFormState, SetStepAction } from 'dhv-form'
import { secureIban } from 'zur-payments'
import { CompleteStepState } from 'dhv-form-complete'
import { CompleteStepSchema } from '../../api/client/complete-step.schema'

import './complete-step.css'
import { ResetFinishAction, StartFinishAction } from 'client/dhv-form/api/client/actions/finish.action'
import { ActionCreator } from 'redux'
import { ROUTES } from 'dhv-form/ui/client/dhv-form.component'
import { OverlayWrapper } from 'client/zur-ui-forms/ui/overlay-wrapper/overlay-wrapper.component'
import { PaymentType } from 'client/dhv-form-payment/api/client/state/payment-step.state'
import { Broker } from 'client/dhv-form/api/client/actions/broker.action'
import { Card } from 'client/zur-ui-forms/ui/card/card'

function round(value: number) {
  return Math.round(value * 100) / 100
}

const labelColumns = {
  sm: 5,
  lg: 4,
}

const controlColumns = {
  sm: 7,
  lg: 8,
}

interface CompleteStepProps extends RouteComponentProps<{}> {
  dhvForm: DhvFormState
  completeStep: CompleteStepState
  dispatchFinish: (dhvForm: DhvFormState) => void
  startFinish: ActionCreator<StartFinishAction>
  resetFinish: ActionCreator<ResetFinishAction>
  setStep: ActionCreator<SetStepAction>
  startSearchBroker: ActionCreator<any>
  resetSearchBroker: ActionCreator<any>
  selectBroker: ActionCreator<any>
  removeBroker: ActionCreator<any>
}

class CompleteStep extends React.Component<CompleteStepProps, {}> {
  myRef1: any

  constructor(props: CompleteStepProps) {
    super(props)
    this.myRef1 = React.createRef()
    if (props.dhvForm.finish.status === 200) {
      this.props.resetFinish()
      resetStorage()
      this.props.setStep(6)
      this.props.history.push(ROUTES.THANKS)
    }
  }

  showError = (field: any) => {
    return field.touched && !field.valid
  }

  onSubmit = () => {
    this.props.startFinish()
    this.props.dispatchFinish(this.props.dhvForm)
    return false
  }

  back = () => {
    this.props.history.push('/vertragsunterlagen')
  }

  componentDidMount() {
    if (this.props.dhvForm.finish.status === 200) {
      // do nothing
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: CompleteStepProps) {
    if (nextProps.dhvForm.finish.status === 200) {
      // do nothing
    }
  }

  shouldComponentUpdate(props: CompleteStepProps) {
    return !_.isEqual(this.props.completeStep, props.completeStep) || !_.isEqual(this.props.dhvForm.finish, props.dhvForm.finish)
  }

  render() {
    const { dhvForm } = this.props
    const { finish, completeStep, rateStep, contractStep, personStep, paymentStep } = dhvForm
    const validators = CompleteStepSchema.getValidators(completeStep, {
      context: completeStep,
      autoClean: true,
    })

    const hideMilesAndMoreField = completeStep.brokerId !== '216066783' || completeStep.brokerEmail !== 'mm@zurich.com'

    return (
      <div
        onClick={() => {
          this.myRef1.current.closeOverlay()
        }}
      >
        <Form model="dhvForm.completeStep" className="form-horizontal insurance-form complete-form" onSubmit={this.onSubmit}>
          <fieldset>
            <legend>
              <h3>Ihre Angaben auf einen Blick</h3>
            </legend>
            <div className="form-content summary" style={{ paddingTop: 5 }}>
              {(rateStep.scope === 'haftpflicht' || rateStep.scope === 'haftpflichtKasko') && (
                <div>
                  <h4>Haftpflicht</h4>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Nutzung:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>
                        {rateStep.usage === 'p'
                          ? 'Der Multicopter wird privat genutzt'
                          : 'Der Multicopter wird gewerblich genutzt'}
                      </FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Selbstbehalt:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{rateStep.haftpflicht.deductible}</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Versicherungssumme:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>
                        {currencyFormatter(parseInt(rateStep.haftpflicht.insuranceSum, 10))}
                      </FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Geltungsbereich:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>
                        {rateStep.haftpflicht.coverage === 'eu'
                          ? 'Europa inkl Mittelmeeranrainerstaaten'
                          : 'Weltweit (ohne USA und Kanada)'}
                      </FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Nettobeitrag:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{currencyFormatter(rateStep.haftpflicht.priceNetto)}</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Versicherungsteuer:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>
                        {currencyFormatter(round(rateStep.haftpflicht.priceBrutto - rateStep.haftpflicht.priceNetto))}
                      </FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Gesamtbeitrag:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{currencyFormatter(rateStep.haftpflicht.priceBrutto)}</FormControl.Static>
                    </Col>
                  </Row>
                </div>
              )}
              {(rateStep.scope === 'kasko' || rateStep.scope === 'haftpflichtKasko') && (
                <div>
                  <h4>Kasko</h4>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Nutzung:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>
                        {rateStep.usage === 'p'
                          ? 'Der Multicopter wird privat genutzt'
                          : 'Der Multicopter wird gewerblich genutzt'}
                      </FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Listenpreis:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{currencyFormatter(parseInt(rateStep.kasko.dronePrice, 10))}</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Vermietung:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>
                        {rateStep.kasko.droneLending
                          ? 'Multicopter oder das Zubehör werden einem Dritten als Mieter, Pächter, Entleiher oder Verwahrer übergeben.'
                          : 'Multicopter oder das Zubehör werden nicht vermietet, verpachtet, verliehen oder zur Verwahrung an Dritte übergeben.'}
                      </FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Selbstbehalt:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>10% (mindestens 250 EUR)</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Geltungsbereich:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>Weltweit</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Nettobeitrag:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{currencyFormatter(rateStep.kasko.priceNetto)}</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Versicherungsteuer:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>
                        {currencyFormatter(round(rateStep.kasko.priceBrutto - rateStep.kasko.priceNetto))}
                      </FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Gesamtbeitrag:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{currencyFormatter(rateStep.kasko.priceBrutto)}</FormControl.Static>
                    </Col>
                  </Row>
                </div>
              )}
              <h4>Vertragsgrundlagen</h4>
              <Row>
                <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                  Versicherungsbeginn:
                </Col>
                <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                  <FormControl.Static>{moment(contractStep.startDate).format('L')}</FormControl.Static>
                </Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                  Bisherige Ablehnungen:
                </Col>
                <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                  <FormControl.Static>
                    Es wurden zuvor keine Anträge zur Drohnenversicherung abgelehnt oder Drohnenversicherungsverträge durch einen
                    Vorversicherer gekündigt.
                  </FormControl.Static>
                </Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                  Bisherige Schäden:
                </Col>
                <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                  <FormControl.Static>
                    Es sind in den vergangen fünf Jahren keine Schäden (auch keine nicht versicherten) eingetreten.
                  </FormControl.Static>
                </Col>
              </Row>
              {rateStep.usage === 'g' && (
                <div>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Baujahr:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{contractStep.droneBuildYear}</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Hersteller:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{contractStep.droneManufacturer}</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Typ (Verkaufsbezeichnung):
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{contractStep.droneType}</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      Seriennummer:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{contractStep.droneNumber}</FormControl.Static>
                    </Col>
                  </Row>
                </div>
              )}
              <h4>Versicherungsnehmer</h4>
              <Row>
                <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                  Name:
                </Col>
                <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                  <FormControl.Static>{getFullName(personStep as Person)}</FormControl.Static>
                </Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                  Straße:
                </Col>
                <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                  <FormControl.Static>{personStep.street}</FormControl.Static>
                </Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                  PLZ / Ort:
                </Col>
                <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                  <FormControl.Static>
                    {personStep.postalCode} {personStep.city}
                  </FormControl.Static>
                </Col>
              </Row>
              {(rateStep.usage === 'p' || personStep.foa !== 'Firma') && (
                <Row>
                  <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                    Geburtsdatum:
                  </Col>
                  <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                    <FormControl.Static>{moment(personStep.birthdate).format('L')}</FormControl.Static>
                  </Col>
                </Row>
              )}
              <div>
                <Row>
                  <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                    Telefon-Nr.:
                  </Col>
                  <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                    <FormControl.Static>{personStep.phone}</FormControl.Static>
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                    Email:
                  </Col>
                  <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                    <FormControl.Static>{personStep.email}</FormControl.Static>
                  </Col>
                </Row>
              </div>
              <h4>Zahlungsinformationen</h4>
              <Row>
                <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                  Zahlungsart:
                </Col>
                <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                  <FormControl.Static>{paymentStep.paymentType}</FormControl.Static>
                </Col>
              </Row>
              {paymentStep.paymentType === PaymentType.Lastschrift && (
                <div>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      IBAN:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{secureIban(paymentStep.directDebit.iban)}</FormControl.Static>
                    </Col>
                  </Row>
                  <Row>
                    <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                      BIC:
                    </Col>
                    <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                      <FormControl.Static>{paymentStep.directDebit.bic}</FormControl.Static>
                    </Col>
                  </Row>
                  {paymentStep.directDebit.sameOwner === 'ja' && (
                    <Row>
                      <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                        Kontoinhaber:
                      </Col>
                      <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                        <FormControl.Static>wie Versicherungsnehmer</FormControl.Static>
                      </Col>
                    </Row>
                  )}
                  {paymentStep.directDebit.sameOwner === 'nein' && (
                    <div>
                      <Row>
                        <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                          Kontoinhaber:
                        </Col>
                        <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                          <FormControl.Static>{getFullName(paymentStep.directDebit.owner)}</FormControl.Static>
                        </Col>
                      </Row>
                      <Row>
                        <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                          Straße:
                        </Col>
                        <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                          <FormControl.Static>{paymentStep.directDebit.owner.street}</FormControl.Static>
                        </Col>
                      </Row>
                      <Row>
                        <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                          PLZ / Ort:
                        </Col>
                        <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                          <FormControl.Static>
                            {paymentStep.directDebit.owner.postalCode} {paymentStep.directDebit.owner.city}
                          </FormControl.Static>
                        </Col>
                      </Row>
                      <Row>
                        <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                          Email:
                        </Col>
                        <Col sm={controlColumns.sm} lg={controlColumns.lg}>
                          <FormControl.Static>{paymentStep.directDebit.owner.email}</FormControl.Static>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <h4 />
                </div>
              )}
            </div>
          </fieldset>
          <fieldset>
            <legend>
              <h3>Wer gut informiert ist, kann richtig Entscheiden</h3>
            </legend>
            <div className="form-content">
              <Row>
                <Col sm={12}>
                  <p>
                    Meine Einwilligung in die Kontaktaufnahme zu Kundenbefragungen und interessanten Angeboten
                    <br />
                    <br />
                    Gerne möchten wir von Ihnen lernen und erfahren, wie Sie den Kontakt mit uns empfinden. Um Sie zukünftig nach
                    Ihren Erfahrungen zu fragen und auf aktuelle Entwicklungen sowie interessante Angebote aufmerksam zu machen,
                    bitten wir Sie um Ihr Einverständnis.
                  </p>
                  <FormGroup>
                    <Control.checkbox
                      model="dhvForm.completeStep.acceptedContact"
                      component={Checkbox}
                      controlProps={{
                        id: 'acceptedContact',
                        label: (
                          <span>
                            <strong>Ich willige ein</strong>, von den Gesellschaften der Zurich Gruppe Deutschland* und von dem
                            für mich zuständigen Vermittler zu Kundenzufriedenheitsbefragungen/Marktforschung sowie zu
                            Versicherungsprodukten/sonstigen Finanzdienstleistungen der Gesellschaften der Zurich Gruppe
                            Deutschland und deren Kooperationspartnern* kontaktiert zu werden. Hierzu können die von mir
                            bereitgestellten Kontaktdaten wie folgt genutzt werden:
                          </span>
                        ),
                      }}
                    />
                  </FormGroup>
                  {completeStep.acceptedContact && (
                    <div>
                      <FormGroup>
                        <Col sm={12}>
                          <Control.checkbox
                            model="dhvForm.completeStep.acceptedEmail"
                            component={Checkbox}
                            disabled={!completeStep.acceptedContact}
                            controlProps={{
                              id: 'acceptedEmail',
                              label: 'per E-Mail',
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={12}>
                          <Control.checkbox
                            model="dhvForm.completeStep.acceptedPhone"
                            component={Checkbox}
                            disabled={!completeStep.acceptedContact}
                            controlProps={{
                              id: 'acceptedPhone',
                              label: 'per Festnetztelefon',
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={12}>
                          <Control.checkbox
                            model="dhvForm.completeStep.acceptedMobile"
                            component={Checkbox}
                            disabled={!completeStep.acceptedContact}
                            controlProps={{
                              id: 'acceptedMobile',
                              label: 'per Mobiltelefon',
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={12}>
                          <Control.checkbox
                            model="dhvForm.completeStep.acceptedSms"
                            component={Checkbox}
                            disabled={!completeStep.acceptedContact}
                            controlProps={{
                              id: 'acceptedSms',
                              label: 'per Messenger-Dienst (z.B. SMS, WhatsApp)',
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  )}
                  <p>
                    Meine Einwilligung kann ich jederzeit per E-Mail ohne Einfluss auf die Geschäftsbeziehung für die Zukunft
                    widerrufen, z.B. unter &nbsp;
                    <a href="www.zurich.de/einwilligung" target="_blank">
                      www.zurich.de/einwilligung
                    </a>
                    .
                  </p>
                </Col>
              </Row>
              <p className="sub-note">
                * Nähere Angaben finden Sie hierzu unter{' '}
                <a href="https://www.zurich.de/gesellschaftsangaben" target="_blank">
                  www.zurich.de/gesellschaftsangaben
                </a>
              </p>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              <h3>Vermittler auswählen</h3>
            </legend>
            <div className="form-content">
              <Row>
                <Col sm={4}>
                  <FormGroup className={completeStep.brokerError ? 'has-error' : ''}>
                    <Text label="Vermittler suchen" name="brokerSearch" />
                    {completeStep.brokerError && (
                      <div className="textfield-helper">
                        <span className="checkbox-helper-message ">Fehler in der Abfrage der Vermittlersuche</span>
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <button
                    className="btn btn--primary btn-final"
                    style={{ marginTop: 0 }}
                    onClick={() => this.props.startSearchBroker(completeStep.brokerSearch)}
                    type="button"
                    disabled={completeStep.brokerSearching || completeStep.brokerSearch.length === 0}
                  >
                    {completeStep.brokerSearching ? <Spinner /> : <span className="icon i-cta" />}
                    Jetzt Vermittler suchen
                  </button>
                </Col>
              </Row>

              <Row>
                <Col componentClass={ControlLabel} sm={labelColumns.sm} lg={labelColumns.lg}>
                  <div className="card-container">
                    {completeStep.brokerSearching && <Spinner />}
                    {completeStep.broker && (
                      <Card
                        broker={completeStep.broker}
                        selected={!!completeStep.broker}
                        selectBroker={() => undefined}
                        removeBroker={this.props.removeBroker}
                      />
                    )}
                    {!completeStep.broker &&
                      completeStep.brokerResult.map((broker: Broker, index: number) => {
                        return (
                          <Card
                            key={index}
                            broker={broker}
                            selected={!!completeStep.broker}
                            selectBroker={this.props.selectBroker}
                            removeBroker={this.props.removeBroker}
                          />
                        )
                      })}
                  </div>
                </Col>
              </Row>
              <ZFormGroup
                label={
                  <span>
                    <label htmlFor="brokerId">Vermittlernummer</label>
                    <OverlayWrapper
                      ref={this.myRef1}
                      message={''}
                      tsx={
                        <div>
                          Bitte verwenden Sie hier Ihre neunstellige Vermittlernummer bestehend aus Gesellschaftsnummer und
                          Agenturnummer. Beispiel: <span style={{ color: 'red' }}>21</span>
                          <span style={{ color: '#4066b3' }}>1520017</span> (
                          <span style={{ color: 'red' }}>Gesellschaftsnummer</span>,{' '}
                          <span style={{ color: '#4066b3' }}>Agenturnummer</span>)
                        </div>
                      }
                    />
                  </span>
                }
                error={this.showError(completeStep.forms.brokerId)}
              >
                <Text
                  name="brokerId"
                  disabled={this.props.dhvForm.completeStep.disableBrokerFields ? true : false}
                  validators={validators.brokerId}
                  messages={{
                    regEx: 'Die Vermittlernummer muss aus genau 9 Zahlen bestehen',
                  }}
                />
                <div className="textfield-helper">
                  <p className="textfield-helper-message">
                    Angabe nur erforderlich wenn die Beantragung mit einem Zurich Versicherungspartner erfolgt.
                  </p>
                </div>
              </ZFormGroup>
              {completeStep.brokerId && completeStep.forms.brokerId.valid && (
                <div>
                  <ZFormGroup>
                    <Text
                      label="E-Mail Adresse Vermittler*"
                      name="brokerEmail"
                      disabled={this.props.dhvForm.completeStep.disableBrokerFields ? true : false}
                    />
                  </ZFormGroup>
                  <ZFormGroup>
                    <Text
                      label="E-Mail Adresse Vermittler Variante 2"
                      name="brokerEmail2"
                      disabled={this.props.dhvForm.completeStep.disableBrokerFields ? true : false}
                    />
                  </ZFormGroup>
                </div>
              )}
            </div>
          </fieldset>

          <div style={{ display: hideMilesAndMoreField ? 'none' : 'block' }}>
            <FieldSet>
              <FieldSetContent>
                <fieldset className="nested-fieldset">
                  <ZFormGroup error={this.showError(completeStep.forms.milesAndMore)}>
                    <Text
                      model="dhvForm.completeStep.milesAndMore"
                      label="Miles and More Kundennummer"
                      name="completeStep.milesAndMore"
                      validators={validators.milesAndMore}
                      messages={{
                        required: 'Bitte eine gültige Miles and More Kundennummer mit 15 Ziffern angeben.',
                        regEx: 'Bitte eine gültige Miles and More Kundennummer mit 15 Ziffern angeben.',
                        custom: 'Keine gültige Miles and More Kundennummer mit 15 Ziffern.',
                      }}
                    />
                  </ZFormGroup>
                </fieldset>
              </FieldSetContent>
            </FieldSet>
          </div>

          {finish.error !== null && (
            <fieldset>
              <div className="form-content">
                <FormGroup>
                  <HelpBlock className="has-error">{finish.error}</HelpBlock>
                </FormGroup>
              </div>
            </fieldset>
          )}
          <fieldset>
            <button className="btn btn--text btn-prev float-left" type="button" disabled={finish.isLoading} onClick={this.back}>
              Zurück
              <span className="icon icon--arrow-left_24" />
            </button>
            <button
              className="btn btn--primary btn-final float-right"
              disabled={finish.isLoading || finish.status === 200}
              type="submit"
            >
              {finish.isLoading ? <Spinner /> : <span className="icon i-cta" />}
              Jetzt Versicherungsschutz kaufen
            </button>
          </fieldset>
        </Form>
      </div>
    )
  }
}

const wrapped = withRouter<CompleteStepProps>(CompleteStep)
export { wrapped as CompleteStep }
