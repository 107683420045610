import { ActionCreator } from 'redux'

export interface SetBrokerSearchAction {
  type: 'SET_BROKERSEARCH'
  brokerSearch: string
}
export const SET_BROKERSEARCH = 'SET_BROKERSEARCH'
export const setBrokerSearch: ActionCreator<SetBrokerSearchAction> = (brokerSearch: string) => {
  return {
    type: SET_BROKERSEARCH,
    brokerSearch: brokerSearch,
  }
}
