import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'

import { RateStep as RateStepComponent } from '../../ui/client/rate-step.component'

import { AppState } from 'dhv-common'
import {
  setActiveStep,
  setStep,
  setBrokerId,
  setBrokerEmail,
  setDisableBrokerFields,
  startSearchBroker,
  startSearchBrokerFetch,
} from 'dhv-form'
import { calculatePriceAction, resetPrice } from './actions/prices'

const mapStateToProps = (state: AppState) => ({
  steps: state.dhvForm.steps,
  business: state.dhvForm.isBusiness,
  rateStep: state.dhvForm.rateStep,
  completeStep: state.dhvForm.completeStep,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      setStep,
      setActiveStep,
      setBrokerId,
      setBrokerEmail,
      setDisableBrokerFields,
      calculatePriceAction,

      startSearchBroker: startSearchBroker,
      startSearchBrokerFetch: startSearchBrokerFetch,
    },
    dispatch
  )

  const changeUsage = (model: string, value: any) => {
    dispatch(actions.change(model, value))
    dispatch(actions.change('dhvForm.rateStep.scope', value === 'p' ? 'haftpflicht' : ''))
    dispatch(actions.change('dhvForm.rateStep.haftpflicht.coverage', value === 'p' ? 'world' : 'eu'))
    dispatch(actions.change('dhvForm.contractStep.droneManufacturer', value === 'p' ? 'diverse' : ''))
    dispatch(actions.change('dhvForm.contractStep.droneType', value === 'p' ? 'Multicopter & Flugmodelle' : ''))
    dispatch(resetPrice())
  }

  const changeAndResetPrice = (model: string, value: any) => {
    if (model.includes('droneLending')) {
      value = value === 'Ja' ? true : false
    }
    dispatch(actions.change(model, value))
    dispatch(resetPrice())
  }

  return {
    ...boundActionCreators,
    changeAndResetPrice,
    changeUsage,
  }
}

export const RateStep = connect(mapStateToProps, mapDispatchToProps)(RateStepComponent)
