import { SEARCH_BROKER_RESET } from 'client/dhv-form/api/client/actions/broker.action'
import { SET_BROKERSEARCH } from 'client/dhv-form/api/client/actions/set-brokersearch.action'
import { dhvFormStateDefault } from 'dhv-common'

export function brokerSearch(state: string = dhvFormStateDefault.completeStep.brokerSearch || '', action: any): string {
  if (action.type === SET_BROKERSEARCH) {
    return action.brokerSearch
  } else if (action.type === SEARCH_BROKER_RESET) {
    return ''
  } else {
    return state
  }
}
